const id = import.meta.env.VITE_GTM_JP_ID;
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', id);

let ns = document.createElement('noscript');
ns.innerHTML = `
    <iframe
        src="https://www.googletagmanager.com/ns.html?id=${id}"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
        ></iframe>
    `;
document.body.appendChild(ns);
