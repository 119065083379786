import { marketConfig, Markets } from '../config/market-config'

const CreateOneTrustEmbed = () => {
    //  if isBrowser check that window is defined
    const initOT = () => {
      let so = document.createElement('script');
      // let otBlock = document.createElement('script');
      // let sr = document.createElement('script');
      let ho = document.querySelector('head') || document.body;
      so.async = true;
      so.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
      // otBlock.src =
      //   'https://cdn-ukwest.onetrust.com/consent/88a7ce71-c973-4a0a-b556-9f08df90d61a-test/OtAutoBlock.js';
      // sr.src = 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';
      so.setAttribute(
        'data-domain-script',
        '11828868-2198-4dd6-92ba-9ddf810a1d64',
      );
      // h.appendChild(sr);
      ho.appendChild(so);
      // ho.appendChild(otBlock);
    };
    initOT();
    function OptanonWrapper() {}
    OptanonWrapper();
};

// Cookie consent not required on JP market
if(marketConfig.currentMarket !== Markets.Japan) {
  CreateOneTrustEmbed();
}
