
/**
 * Fetches the market specific configs and makes them easily importable and unmutable by other subscribers.
 */
export const marketConfig = Object.freeze({
    currentMarket: import.meta.env.VITE_MARKET_FLAG
})

/**
 * Used to check against market
 * @constants
 */
export const Markets = Object.freeze({
    Japan: 'JP',
    GB: 'GB',
})

/**
 * @constants
 */
const allMarkets = Object.values(Markets);

if (!allMarkets.includes(marketConfig.currentMarket)) {
    throw new Error(
      `Unsupported market flag "${marketConfig.currentMarket}", did you set the correct env variables?`,
    );
  }