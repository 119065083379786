export const CreateOmetriaEmbed = () => {
  const initOmt = () => {
    let sc = document.createElement('script');
    setTimeout(function () {
      const url = '//cdn.ometria.com/tags/6a077ccde2d0a659.js';
      sc.src = url;
      sc.setAttribute('async', 'true');
      document.getElementsByTagName('head')[0].appendChild(sc);
    }, 15);
  };
  initOmt();
};

CreateOmetriaEmbed();
